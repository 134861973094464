<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2000 411"
    enable-background="new 0 0 2000 411"
    xml:space="preserve"
    class="logo"
    fill="currentColor"
  >
    <path
      d="M1013.9,397.6c0,4.6,0,4.7-5.5,4.7
	c-28.7,0-57.4,0-86.1,0c-4.6,0-4.6,0-4.6-4c0-48.8,0-97.6,0-146.4c0-4.4,0-4.4-5.1-4.4c-18.4,0-36.8,0-55.2,0c-4.6,0-4.6,0-4.6,4
	c0,49.1,0,98.1,0,147.2c0.3,2.7-1.1,3.6-4.3,3.6c-29.4-0.1-58.7-0.1-88.1,0c-3.2,0-4.6-0.9-4.2-3.7c0-127.7,0-384,0-384.4
	c0-4.7,0-4.7,5.4-4.7c28.8,0,57.6,0,86.5,0c4.7,0,4.7,0,4.7,4.2c0,48.7,0,97.8,0,146.4c0,4.2,0.3,4.1,5.3,4.1c18.4,0,36.8,0,55.2,0
	c4.4,0,4.4,0,4.4-3.8c0-48.8,0-97.6,0-146.4c0-4.6,0-4.6,5.2-4.6c28.8,0,57.6,0,86.5,0c4.5,0,4.6,0,4.6,4V397.6"
    />
    <path
      d="M1708.3,11.6c0-1.4,0.8-2.1,2.5-2.1
	c63.7,0,127.5,0,191.2,0c5.4,0,5.4,0,5.4,4.7c0,24.6,0,49.3,0,73.9c0,4.3,0,4.3-4.9,4.3c-31,0-62.1,0-93.1,0c-4.5,0-4.5,0-4.5,4
	c0,21.3,0,42.6,0,63.9c0,4,0,4,4.8,4c18.6,0,37.2,0,55.9,0c4.6,0,4.6,0,4.6,4c0,25.1,0,50.2,0,75.3c0,3.8-0.1,3.9-4.4,3.9
	c-18.8,0-37.7,0.1-56.5-0.1c-3.3,0-4.4,0.8-4.4,3.7c0.1,21.4,0.1,42.8,0.1,64.2c0,3.7,0,3.8,4.5,3.8c30.9,0,61.9,0,92.8,0
	c5.2,0,5.2,0,5.2,4.4c0,24.9,0,49.8,0,74.8c0,4.1,0,4.1-4.8,4.1c-63.1,0-126.1,0-189.2,0c-5,0-5.1-0.1-5.1-4.6
	C1708.3,333.7,1708.2,76.3,1708.3,11.6"
    />
    <path
      d="M1521.9,96.7c0-4.3,0-4.3-4.8-4.3
	c-18.8,0-37.7,0-56.5,0c-4.5,0-4.5,0-4.5-3.7c0-25.2,0-50.4-0.1-75.6c0-2.7,0.9-3.6,4.1-3.6c73.4,0.1,146.7,0.1,220.1,0
	c3.4,0,4.3,1,4.3,3.8c-0.1,25.1-0.1,50.2-0.1,75.3c0,4.2-0.4,3.8-4.5,3.8c-18.8,0-37.7,0-56.5,0c-4.9,0-4.9,0-4.9,4.3
	c0,99.8,0,199.7,0,299.5c0,7.1-0.1,6-7,6c-27.9,0-55.9,0-83.8,0c-5.7,0-5.8,0-5.8-4.9V96.7"
    />
    <path
      d="M1297.9,12.6c-0.2-2.1,0.8-3.1,3.5-3
	c4.8,0.1,62.8,0,87.1,0c6,0,6,0,6,5.1c0,99.6,0,199.1,0,298.7c0,6.2-0.8,5.7,6.6,5.7c30.4,0,60.7,0,91.1,0c5.1,0,5.2,0.1,5.2,4.5
	c0,24.7,0,49.5,0,74.2c0,4.5,0,4.6-5.4,4.6h-188.5c-5.5,0-5.5,0-5.5-4.8C1297.9,333.6,1298,77,1297.9,12.6"
    />
    <path
      d="M1182.5,402.2c-5,0-5,0-5.8-4
	c-1.7-8.9-3.4-17.9-4.8-26.9c-0.4-2.7-1.6-3.7-4.9-3.6c-17,0.1-33.9,0.1-50.9,0c-3,0-4.2,0.8-4.7,3.5c-1.5,9.3-3.4,18.6-5,28
	c-0.4,2.2-1.3,3.1-4,3.1c-20.3-0.1-40.6-0.1-60.9,0c-2.9,0-3.6-0.9-3.1-3.3c7.9-42.5,55.1-298.4,70.7-383.9c1.2-6.6,1-5.4,7.5-5.5
	c26.9-0.1,53.9,0,80.8-0.1c3.1,0,4.4,0.6,4.9,3.5c15.3,84,62.7,340.7,70.8,385.1c0.7,4,0.5,4.2-4.3,4.2
	C1254.6,402.2,1196.9,402.2,1182.5,402.2 M1145.7,168.7c-8.1,49.6-16.1,98.4-24.2,147.9c-0.3,1.7,0,2.5,2.2,2.5
	c12.3,0,24.6,0,36.9,0c2,0,2.8-0.8,2.6-2.5C1157.4,267.1,1151.7,218.3,1145.7,168.7"
    />
    <path
      d="M144.2,402.2c-5,0-5,0-5.8-4
	c-1.7-8.9-3.4-17.9-4.8-26.9c-0.4-2.7-1.6-3.7-4.9-3.6c-17,0.1-33.9,0.1-50.9,0c-3,0-4.2,0.8-4.7,3.5c-1.5,9.3-3.4,18.6-5,28
	c-0.4,2.2-1.3,3.1-4,3.1c-20.3-0.1-40.6-0.1-60.9,0c-2.9,0-3.6-0.9-3.1-3.3C8,356.5,55.2,100.6,70.8,15c1.2-6.6,1-5.4,7.5-5.5
	c26.9-0.1,53.9,0,80.8-0.1c3.1,0,4.4,0.6,4.9,3.5C179.4,97,226.9,353.7,235,398c0.7,4,0.5,4.2-4.3,4.2
	C216.3,402.2,158.7,402.2,144.2,402.2 M107.4,168.7c-8.1,49.6-16.1,98.4-24.2,147.9c-0.3,1.7,0,2.5,2.2,2.5c12.3,0,24.6,0,36.9,0
	c2,0,2.8-0.8,2.6-2.5C119.1,267.1,113.5,218.3,107.4,168.7"
    />
    <path
      d="M380.9,125.4
	c-1.1,0.1-1.6-0.4-1.6-1.8c0-8.4-0.1-16.7,0-25.1c0.4-11.2-10-19-20.5-19.1c-11.6-0.7-24.7,7.6-22.7,20.4c1,8.7,4.4,16.9,9.2,24.2
	c8.2,13,18.7,24.3,29.3,35.4c34.3,36,77.7,69.6,94.5,118c10.8,31.5,4.8,65.4-17.8,90.3c-25.7,28.8-63.8,44-102.2,43.1
	c-17.9-0.3-35.5-4.5-52.1-11.2c-29-11.6-55-38-54.7-70.8c0-14,0-27.5,0-41.4c0-1.3,0.4-1.5,1.5-1.5c26.8,0,52.5,0,79.3,0
	c1.7,0,2.3,0.3,2.3,2c0,7.2,0.1,14,0,21.2c-0.1,8.2,3.7,14.1,10.4,18.3c9.5,5.9,22.4,6,31.7-0.4c4.6-3.1,7.9-7.3,9.2-12.8
	c2.5-13-4.9-24.8-12.7-34.4c-20.8-28.2-48-50.8-71.9-76.4c-41.3-42.9-76.8-102-33.9-156.8c22.1-27.7,57.1-43.9,92.3-45.5
	C374,0.3,397.8,4.6,418.8,15c26.8,13.4,45.4,38.2,44.4,68.9c0,13.5,0,26.5,0,40.1c0,0.6-0.3,1.4-1.6,1.4c-26.2,0-52.3,0-78.5,0
	C382.4,125.4,381.6,125.4,380.9,125.4"
    />
    <path
      d="M1967.2,65.1
	c-18.2,0-32.6-14.1-32.6-32c1.2-42.6,64.3-42.9,65.5,0C2000,51.1,1985.7,65.1,1967.2,65.1 M1941.5,33.1c0,14.5,11.3,25.9,25.9,25.9
	c14.4,0.1,26.2-11.7,26.2-26c0-14.2-11.6-25.7-25.9-25.7C1952.8,7.3,1941.5,18.4,1941.5,33.1"
    />
    <path
      d="M1953.3,18.5c0-1.3,0.1-2.5,1.8-2.5
	c8.5,0.5,24.4-3,25.7,8.8c0.4,4.5-1.9,8.9-6.1,10.4c-2.5,0.9-1.9,1.7-0.7,3.1c2.8,3.5,5.5,7.1,8.2,10.6c0.3,0.5,1,1.5-1,1.9
	c-3.1,0.5-5.4-0.4-7.2-3.2c-2.2-3.3-4.8-6.2-7.3-9.3c-1.4-1.7-3.8-2.4-5.8-1.8c-1,0.3-1.1,1.1-1.1,2c0,3,0,7.4,0,10.3
	c0,2.4-0.7,2-3.3,2c-2.8,0-3.1-0.2-3.1-2.3C1953.3,43.8,1953.3,23.4,1953.3,18.5 M1965.4,21.3c0,0-2.6,0.1-3.8,0.1
	c-1.2,0-1.9,0.5-1.9,1.7c0,2.1,0,4.3,0,6.4c0,1,0.5,1.5,1.6,1.5c3,0,6,0.2,9-0.4c2.8-0.6,4-2,3.9-4.6
	C1974,21.2,1969.1,21.4,1965.4,21.3"
    />
    <path
      d="M498.6,14.3c0-4.7,0-4.7,5.4-4.7
	c41.3,0,82.7,0.4,124-0.1c64.3-1,107.8,41.9,107.7,100.7c0.1,10.5,0.2,20.1,0,31.2c0.2,49.4-40.3,91.6-95.4,95.4
	c-13.5,1.2-27,0.5-40.5,0.5c-5,0-5,0-5,4.2v156.1c0,4.7,0,4.7-5.4,4.7c-28.6,0-57.2,0-85.8,0c-5,0-5,0-5-4.2V14.3 M594.8,160.2
	c0,4.2,0,4.2,5,4.2c3.7,0,7.3,0,11,0c16.9,0,30.7-9.5,33.5-23.9c2.1-10.7,1.8-21.8,0.3-32.5c-1.9-14.4-15.4-25.4-34.7-25.4
	c-3.7,0-6.9,0-11,0c-3.3,0-4.2,0.9-4.1,3.6C594.9,98.5,594.8,147.9,594.8,160.2"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLogo',
}
</script>
